// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agenda_container {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px 24px;
    gap: 30px;

    background: #FFFFFF;
}

.agenda_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 29px;
    letter-spacing: 0.145em;
    
    color: #252627;
}

.agenda_text {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.145em;
    
    color: #5C656C;
}

.agenda_text_line {

    margin: 15px auto;
    border: 1px solid #617181;
    width: 1px;
    height: 15px;
    
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    
    .agenda_container {
        gap: 20px;
    }

    .agenda_title {
        font-size: 24px;
    }

    .agenda_text {
        font-size: 18px;
        line-height: 46px;
    }

    .agenda_text_line {
        display: none;
    }
}

@media screen and (min-width: 481px) and (max-width: 820px) {

    .agenda_container {
        padding: 30px 50px;
    }

    .agenda_text {
        font-weight: 400;
        font-size: 24px;
    }

}`, "",{"version":3,"sources":["webpack://./src/containers/agenda/agenda.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,SAAS;;IAET,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,uBAAuB;;IAEvB,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,uBAAuB;;IAEvB,cAAc;AAClB;;AAEA;;IAEI,iBAAiB;IACjB,yBAAyB;IACzB,UAAU;IACV,YAAY;;AAEhB;;AAEA;;IAEI;QACI,SAAS;IACb;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;QACf,iBAAiB;IACrB;;IAEA;QACI,aAAa;IACjB;AACJ;;AAEA;;IAEI;QACI,kBAAkB;IACtB;;IAEA;QACI,gBAAgB;QAChB,eAAe;IACnB;;AAEJ","sourcesContent":[".agenda_container {\n    \n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 48px 24px;\n    gap: 30px;\n\n    background: #FFFFFF;\n}\n\n.agenda_title {\n    font-family: 'Montserrat';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 36px;\n    line-height: 29px;\n    letter-spacing: 0.145em;\n    \n    color: #252627;\n}\n\n.agenda_text {\n    font-family: 'Jost';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 24px;\n    line-height: 26px;\n    text-align: center;\n    letter-spacing: 0.145em;\n    \n    color: #5C656C;\n}\n\n.agenda_text_line {\n\n    margin: 15px auto;\n    border: 1px solid #617181;\n    width: 1px;\n    height: 15px;\n    \n}\n\n@media screen and (min-width: 320px) and (max-width: 480px) {\n    \n    .agenda_container {\n        gap: 20px;\n    }\n\n    .agenda_title {\n        font-size: 24px;\n    }\n\n    .agenda_text {\n        font-size: 18px;\n        line-height: 46px;\n    }\n\n    .agenda_text_line {\n        display: none;\n    }\n}\n\n@media screen and (min-width: 481px) and (max-width: 820px) {\n\n    .agenda_container {\n        padding: 30px 50px;\n    }\n\n    .agenda_text {\n        font-weight: 400;\n        font-size: 24px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
