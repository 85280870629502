// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.card_section_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 40px 10px;
    gap: 40px;

    
    background: #8F8F8F;
}


@media screen and (min-width: 320px) and (max-width: 480px) {
    
    .card_section_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 10px;
        gap: 32px;

        background: linear-gradient(180deg, #8F8F8F 44.27%, #E2DDDA 44.28%s);
    }
}`, "",{"version":3,"sources":["webpack://./src/containers/cards/cardsection.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,SAAS;;;IAGT,mBAAmB;AACvB;;;AAGA;;IAEI;QACI,aAAa;QACb,sBAAsB;QACtB,mBAAmB;QACnB,kBAAkB;QAClB,SAAS;;QAET,oEAAoE;IACxE;AACJ","sourcesContent":["\n.card_section_container {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n    padding: 40px 10px;\n    gap: 40px;\n\n    \n    background: #8F8F8F;\n}\n\n\n@media screen and (min-width: 320px) and (max-width: 480px) {\n    \n    .card_section_container {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        padding: 20px 10px;\n        gap: 32px;\n\n        background: linear-gradient(180deg, #8F8F8F 44.27%, #E2DDDA 44.28%s);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
