// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register_btn_div {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 25px;
    gap: 10px;

    position: relative;
    width: fit-content;
    height: 43px;

    background: #000000;
    text-decoration: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.register_btn {

    width: fit-content;
    height: 19px;

    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media (max-width: 480px) {

    .register_btn_div {
        margin-top: 50px;
    }
}

@media screen and (min-width: 481px) and (max-width: 820px) {

}

@media screen and (min-width: 821px) and (max-width: 1024px) {

}

@media screen and (min-width: 1025px) {

}`, "",{"version":3,"sources":["webpack://./src/components/buttons/buttons.css"],"names":[],"mappings":"AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,SAAS;;IAET,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;;IAEZ,mBAAmB;IACnB,qBAAqB;IACrB,2CAA2C;AAC/C;;AAEA;;IAEI,kBAAkB;IAClB,YAAY;;IAEZ,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,yBAAyB;IACzB,cAAc;AAClB;;AAEA;;IAEI;QACI,gBAAgB;IACpB;AACJ;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA","sourcesContent":["@import '../../fonts.css';\n\n.register_btn_div {\n    /* Auto layout */\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    padding: 12px 25px;\n    gap: 10px;\n\n    position: relative;\n    width: fit-content;\n    height: 43px;\n\n    background: #000000;\n    text-decoration: none;\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n}\n\n.register_btn {\n\n    width: fit-content;\n    height: 19px;\n\n    font-family: 'Jost';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 15px;\n    line-height: 19px;\n    letter-spacing: 0.25em;\n    text-transform: uppercase;\n    color: #FFFFFF;\n}\n\n@media (max-width: 480px) {\n\n    .register_btn_div {\n        margin-top: 50px;\n    }\n}\n\n@media screen and (min-width: 481px) and (max-width: 820px) {\n\n}\n\n@media screen and (min-width: 821px) and (max-width: 1024px) {\n\n}\n\n@media screen and (min-width: 1025px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
